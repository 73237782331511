import React, { useEffect, useState } from 'react';
import { withPermissionsAndRoles, withSession } from '../../session';
import NotificationIcon from './notificationIcon';
import HelpIcon from './helpIcon';
import FeedbackIcon from './feedbackIcon';
import { hasESIClientUserRole, hasUSAClientUserRole } from '../../common/util';


function TopMenuOptions({ roles = [], session }) {
  const [fetchedRoles, setFetchedRoles] = useState([]);

  useEffect(() => {
    if (roles?.length > 0) {
      setFetchedRoles(roles);
    }
  }, [roles]);

  return (
    <>
      {roles?.length > 0 && (hasUSAClientUserRole(fetchedRoles) || hasESIClientUserRole(fetchedRoles)) && <>
        <FeedbackIcon />
        <NotificationIcon region={session.region} />
        <HelpIcon roles={fetchedRoles} />
      </>}
    </>
  );
}

export default withSession(withPermissionsAndRoles(TopMenuOptions));
